import React from "react"
import classnames from "classnames"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => {
  const siteTitle = "Seite nicht gefunden"

  return (
    <Layout title={siteTitle}>
      <SEO title={siteTitle} />
      <div
        className={classnames("wrapper content")}
        style={{ marginTop: "150px" }}
      >
        <h1>Seite nicht gefunden</h1>
        <p style={{ textAlign: "center" }}>Diese Seite existiert nicht.</p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
